import { deflate, inflate } from 'pako'

import { isReaction } from '@/shared/utils/reactionHelper.js'

/*
 * We're mirroring options from app/helpers/image_helper.rb. If you make any
 * changes here, make sure they are duplicated there.
 */

const MOLECULE_IMAGE_PATH = '/cdd/molecule_image'

export function compressForParam(structure) {
  return btoa(deflate(structure, { to: 'string' }))
}

export function decompressFromParam(string) {
  return inflate(atob(string), { to: 'string' })
}

export function moleculeImagePath(structure, editorContext) {
  const DEFAULT_PARAMS = {
    width: 219,
    height: 219,
    auto_scale: '1.0',
    image_format: 'svg',
  }

  const ELN_PARAMS = {
    ...DEFAULT_PARAMS,
    height: 248,
    width: 248,
  }

  const ELN_REACTION_PARAMS = {
    ...ELN_PARAMS,
    height: 248,
    width: 864,
  }

  const PARAMS_MAP = {
    'eln': ELN_PARAMS,
    'rxn': ELN_REACTION_PARAMS,
  }

  if (editorContext == 'auto') {
    editorContext = isReaction(structure) ? 'rxn' : 'eln'
  }

  const structureForQuery = compressForParam(structure)
  const params = PARAMS_MAP[editorContext] ? PARAMS_MAP[editorContext] : DEFAULT_PARAMS

  let queryString = $.param({ structure: structureForQuery, ...params })
  // backend returns image paths with sorted keys - avoids redraw of inserted structures
  queryString = queryString.split('&').sort().join('&')

  return `${MOLECULE_IMAGE_PATH}?${queryString}`
}
